import React, { useEffect } from 'react';
import { getIsOnboarding, isAuthenticated, logout } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import { Stepper } from 'react-form-stepper';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import { setActiveStep } from '../../store1/actions/onBoardingAction';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/logo/logo2.png';
import PrimaryButton from '../../shared/Button/CustomButton';

const Onboarding = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log(getIsOnboarding(), isAuthenticated(), 'getIsOnboarding');
    const { activeStep } = useSelector((state) => state.onBoardForm);

    console.log(activeStep, 'ActiveStep');

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login');
        }
        if (getIsOnboarding()) {
            navigate('/on-boarding');
        } else {
            navigate('/dashboard');
        }
    }, []);

    const steps = [
        { title: 'User details', onClick: () => dispatch(setActiveStep(0)) },
        { title: 'Payment', onClick: () => dispatch(setActiveStep(1)) },
        { title: 'Agreement Detail', onClick: () => dispatch(setActiveStep(2)) },
    ];

    function getSectionComponent() {
        switch (activeStep) {
            case 0:
                return <FirstStep />;
            case 1:
                return <SecondStep />;
            case 2:
                return <ThirdStep />;
            default:
                return null;
        }
    }

    return (
        <>
            <div className="nav-header  w-full px-4 d-flex justify-content-between align-items-center mt-4">
                <img src={logo} className="brand-title" alt="" />
                <PrimaryButton
                    className="mr-2"
                    onClick={() =>
                        logout(() => {
                            navigate('/');
                        })
                    }
                >
                    Logout
                </PrimaryButton>
            </div>

            <div
                className="p-3 d-flex flex-column align-items-center justify-content-center "
                style={{ minHeight: '100vh' }}
            >
                <h3 className="form-title text-center">Client Onboarding</h3>
                <div className="dz-separator-outer m-b5">
                    <div className="dz-separator bg-primary style-liner"></div>
                </div>
                <p className="px-2 text-center">Enter your personal details below: </p>
                <div className="text-center w-100 mx-auto">
                    <Stepper
                        className="multistep-option"
                        onClick={(e) => e.preventDefault()}
                        styleConfig={{
                            activeBgColor: '#2b7cff',
                            activeTextColor: '#fff',
                            completedBgColor: '#085de6',
                            completedTextColor: '#fff',
                            size: '3em',
                        }}
                        steps={steps}
                        activeStep={activeStep}
                    />
                </div>
                <div className="text-center w-100 p-3 lg:p-5">{getSectionComponent()}</div>
            </div>
        </>
    );
};

export default Onboarding;
