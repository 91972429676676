import React from 'react';

import { Card, Col, Row, Table } from 'react-bootstrap';
import ChartPie from '../jsx/components/charts/Chartjs/pie';
import { CustomCard } from '../shared/Cards/CustomCards';
import pMinDelay from 'p-min-delay';
import loadable from '@loadable/component';

const Settlement = () => {

    const overviewData = [
        {
            id: 1,
            status: "Pending",
            custodianDetails: "james@gmail.com",
            instructionType: "Equities",
            transactionID: "T1234-5678-9012-3456",
        },
        {
            id: 1,
            status: "Sent",
            custodianDetails: "james@gmail.com",
            instructionType: "Bonds",
            transactionID: "T1234-5678-9012-3456",
        },
        {
            id: 1,
            status: "Pending",
            custodianDetails: "james@gmail.com",
            instructionType: "Derivatives",
            transactionID: "T1234-5678-9012-3456",
        },
        {
            id: 1,
            status: "Pending",
            custodianDetails: "james@gmail.com",
            instructionType: "Equity",
            transactionID: "Matched",
        },
        {
            id: 1,
            status: "Sent",
            custodianDetails: "james@gmail.com",
            instructionType: "Bonds",
            transactionID: "T1234-5678-9012-3456",
        },
        {
            id: 1,
            status: "Pending",
            custodianDetails: "james@gmail.com",
            instructionType: "Derivatives",
            transactionID: "T1234-5678-9012-3456",
        },
    ];

    const assetData = [
        {
            id: 1,
            deliveryStatus: "Pending",
            paymentStatus: "Pending",
            dvpIndicator: "Delivery",
            confirmation: "Completed",
        },
        {
            id: 1,
            deliveryStatus: "In Progress",
            paymentStatus: "In Progress",
            dvpIndicator: "Payment",
            confirmation: "Completed",
        },
        {
            id: 1,
            deliveryStatus: "Completed",
            paymentStatus: "Pending",
            dvpIndicator: "Delivery",
            confirmation: "",
        },
        {
            id: 1,
            deliveryStatus: "In Progress",
            paymentStatus: "In Progress",
            dvpIndicator: "Payment",
            confirmation: "Completed",
        },
        {
            id: 1,
            deliveryStatus: "In Progress",
            paymentStatus: "Completed",
            dvpIndicator: "Delivery",
            confirmation: "Completed",
        },
        {
            id: 1,
            deliveryStatus: "Completed",
            paymentStatus: "Completed",
            dvpIndicator: "Payment",
            confirmation: "",
        },
    ];

    const settlementTrackerData = [
        {
            id: 1,
            transactionDate: "09-03-2024",
            settlementDate: "09-03-2024",
            countdownTimer: "4 hours",
            status: "Lorem Ipsum is simply dummy text",
        },
        {
            id: 1,
            transactionDate: "09-03-2024",
            settlementDate: "09-03-2024",
            countdownTimer: "4 hours",
            status: "Lorem Ipsum is simply dummy text",
        },
        {
            id: 1,
            transactionDate: "09-03-2024",
            settlementDate: "09-03-2024",
            countdownTimer: "4 hours",
            status: "Lorem Ipsum is simply dummy text",
        },
        {
            id: 1,
            transactionDate: "09-03-2024",
            settlementDate: "09-03-2024",
            countdownTimer: "4 hours",
            status: "Lorem Ipsum is simply dummy text",
        },
        {
            id: 1,
            transactionDate: "09-03-2024",
            settlementDate: "09-03-2024",
            countdownTimer: "4 hours",
            status: "Lorem Ipsum is simply dummy text",
        },
        {
            id: 1,
            transactionDate: "09-03-2024",
            settlementDate: "09-03-2024",
            countdownTimer: "4 hours",
            status: "Lorem Ipsum is simply dummy text",
        },
    ];

    const ApexLine2 = loadable(() => pMinDelay(import('../jsx/components/Dashboard/LineChart/Line2'), 1000));

    return (
        <>
            <Row className='flex'>
                <Col xl={6} md={6}>
                    <CustomCard title="Settlement Progress">
                        <ChartPie height={200} />
                    </CustomCard>
                </Col>
                <Col xl={6} lg={6}>
                    <Card>
                        <Card.Header>
                            <h4 className="card-title">Success Rate</h4>
                        </Card.Header>
                        <Card.Body>
                            <ApexLine2 />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Col xl={12} lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Settlement Instructions Overview</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Instruction Status</strong>
                                    </th>
                                    <th>
                                        <strong>Custodian Details</strong>
                                    </th>
                                    <th>
                                        <strong>Instruction Type</strong>
                                    </th>
                                    <th>
                                        <strong>Transaction ID</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {overviewData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.status}</td>
                                        <td >{item.custodianDetails}</td>
                                        <td >{item.instructionType}</td>
                                        <td >{item.transactionID}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
            <Col xl={12} lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Asset Delivery & Payment</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Delivery Status</strong>
                                    </th>
                                    <th>
                                        <strong>Payment Status</strong>
                                    </th>
                                    <th>
                                        <strong>DvP Indicator</strong>
                                    </th>
                                    <th>
                                        <strong>Confirmation</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {assetData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.deliveryStatus}</td>
                                        <td >{item.paymentStatus}</td>
                                        <td >{item.dvpIndicator}</td>
                                        <td >{item.confirmation}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
            <Col xl={12} lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Settlement Tracker</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Transaction Date</strong>
                                    </th>
                                    <th>
                                        <strong>Settlement Date</strong>
                                    </th>
                                    <th>
                                        <strong>Countdown Timer</strong>
                                    </th>
                                    <th>
                                        <strong>Delays/Discrepancies</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {settlementTrackerData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.transactionDate}</td>
                                        <td >{item.settlementDate}</td>
                                        <td >{item.countdownTimer}</td>
                                        <td >{item.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default Settlement;
