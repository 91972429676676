import api from '../../services/api';
import { authenticate, isAuthenticated } from '../../services/auth';
import endPoints from '../../services/endPoints';
import { types } from '../types/types';
import { showToast } from './toastAction';

export const onLogin = (payload, navigate, setLoading, setIsOtpSent, setData) => async (dispatch) => {
    setLoading(true);
    payload = { email: payload.email, password: payload.password };
    const res = await api('post', endPoints.LOGIN, payload);
    if (res.success) {
        if (res.data?.two_factor_auth) {
            setIsOtpSent(true);
            setData((prev) => ({ ...prev, refreshToken: res.data.token }));
        } else {
            dispatch({ type: types.PROFILE, payload: res.data });
            authenticate(res.data?.token?.access, false, () => {
                if (isAuthenticated()) {
                    navigate('/dashboard');
                } else {
                    navigate('/login');
                }
            });
        }
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};

export const onLoginVerify = (data, navigate, setLoading) => async (dispatch) => {
    setLoading(true);
    data = { otp_code: data?.otpCode, token: data?.refreshToken };
    const res = await api('post', endPoints.LOGIN_TWO_AUTH, data);
    if (res.success) {
        dispatch({ type: types.PROFILE, payload: res.data });
        authenticate(res.data.token.access, false, () => {
            if (isAuthenticated()) {
                navigate('/dashboard');
            } else {
                navigate('/login');
            }
        });
        // dispatch(showToast({ severity: 'success', summary: res.message }));
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};

export const onGetOtpverify = (data, setLoading, next) => async (dispatch) => {
    setLoading(true);
    data = { email: data.email, purpose: data.purpose };
    const res = await api('post', endPoints.GET_OTP, data);
    if (res.success) {
        next();
    } else {
        // dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};

export const onBoardingClient = (data, setLoading, navigate) => async (dispatch) => {
    setLoading(true);

    let payload = {
        contact: {
            phone_number: data?.phoneNumber,
            street_address: [data.streetAddress],
            city: data.city,
            postal_code: data.postalCode,
            state: data.state,
        },
        identity: {
            tax_id_type: data.taxIdType,
            given_name: data.givenName,
            family_name: data.familyName,
            date_of_birth: data.dateOfBirth,
            country_of_tax_residence: data.countryOfTaxResidence,
            funding_source: [data.fundingSource],
            tax_id: data.taxId,
            country_of_birth: data.countryOfBirth,
            country_of_citizenship: data.countryOfCitizenship,
        },
        disclosures: {
            is_control_person: data.isControlPerson,
            is_affiliated_exchange_or_finra: data.isAffiliatedExchangeOrFinra,
            is_politically_exposed: data.isPoliticallyExposed,
            immediate_family_exposed: data.immediateFamilyExposed,
        },
        agreements: data?.agreements?.map((item) => ({
            agreement: item.agreement,
            signed_at: new Date(item.signedAt).toISOString(),
            ip_address: item.ipAddress,
        })),
    };
    const res = await api('post', endPoints.ONBOARDING_CLIENT, payload);
    if (res.success) {
        authenticate(res.data.token.access, false, () => {
            if (isAuthenticated()) {
                dispatch({ type: types.RESET_FORM });
                navigate('/dashboard');
            } else {
                navigate('/login');
            }
        });
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};
export const onSignUp = (data, setLoading, next) => async (dispatch) => {
    setLoading(true);
    data = {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        password: data.password,
        otp_code: data.otpCode,
    };
    const res = await api('post', endPoints.SIGNUP, data);
    if (res.success) {
        next(res);
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};

export const getProfile = () => async (dispatch) => {
    const res = await api('get', endPoints.PROFILE);
    if (res.success) {
        dispatch({ type: types.PROFILE, payload: res.data });
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
};
export const onTwoFactorAuthChange = (data, setLoading, next) => async (dispatch) => {
    setLoading(true);
    data = { otp_code: data?.otpCode };
    const res = await api('post', endPoints.TWO_FACTOR_AUTH, data);
    if (res.success) {
        next();
    } else {
        dispatch(showToast({ severity: 'error', summary: res.message }));
    }
    setLoading(false);
};
