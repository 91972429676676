import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

export default function Toast() {
    const { toastInfo } = useSelector((state) => state?.toast);

    useEffect(() => {
        if (toastInfo?.summary && toastInfo?.severity) {
            const showToast = toast[toastInfo.severity] || toast.info;
            showToast(toastInfo.summary, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }, [toastInfo]);

    return <ToastContainer />;
}
