import React, { useContext } from 'react';

/// React router dom
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

/// Css
import './index.css';
import './chart.css';
import './step.css';

/// Layout
import Nav from './layouts/nav';
import Footer from './layouts/Footer';
//import Main from './layouts/Main';

import ScrollToTop from './layouts/ScrollToTop';
import { ThemeContext } from '../context/ThemeContext';
import { PrivateRoutes } from '../routes/allRoutes';
import { getIsOnboarding, isAuthenticated } from '../services/auth';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    ArcElement,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    RadialLinearScale,
    ArcElement,
    PointElement,
    LineElement,
);
const Markup = () => {
    return (
        <>
            <Routes>
                <Route element={<Auth />}>
                    {PrivateRoutes.map((route, key) => (
                        <Route key={key} path={route.path} element={route.element} />
                    ))}
                </Route>
            </Routes>
            <ScrollToTop />
        </>
    );
};
const Auth = () => {
    const { menuToggle } = useContext(ThemeContext);
    console.log(getIsOnboarding(), isAuthenticated(), 'getIsOnboarding');

    if (isAuthenticated()) {
        if (getIsOnboarding()) {
            return <Navigate to="/on-boarding" />;
        } else {
            return (
                <div id="main-wrapper" className={`show ${menuToggle ? 'menu-toggle' : ''}`}>
                    <Nav />
                    <div className="content-body" style={{ minHeight: window.innerHeight - 45 }}>
                        <div className="container-fluid">
                            <Outlet />
                        </div>
                    </div>
                    <Footer />
                </div>
            );
        }
    } else {
        return <Navigate to="/login" />;
    }
};
export default Markup;
