import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { CustomDropdown } from '../shared/Inputs/CustomDropdown';
import { CustomCard } from '../shared/Cards/CustomCards';
import CandlestickChart from '../jsx/components/Dashboard/CandlestickChart/CandlestickChart';
import { CustomInput } from '../shared/Inputs/CustomInput';

const Scanner = () => {
    return (
        <Row>
            <Col md={12}>
                <CustomCard >
                    <Row className='w-100'>
                        <Col md={6} className='mb-3'>
                            <CustomDropdown label="Instrument" />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <CustomDropdown label="Location" />
                        </Col>
                        <Col md={4} className='mb-3'>
                            <CustomDropdown label="Filter" />
                        </Col>
                        <Col md={2} className='mb-3'>
                            <CustomInput label="Value" inputClass="bg-transparent border-primary" />
                        </Col>
                        <Col md={6} className='mb-3'>
                            <CustomDropdown label="Sort By" />
                        </Col>
                    </Row>

                </CustomCard>
            </Col>
            <Col xl={12} lg={12}>
                <CustomCard title="Result">
                        <CandlestickChart />
                </CustomCard>
            </Col>
        </Row>
    );
};

export default Scanner;
