import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { CustomCard } from '../shared/Cards/CustomCards';
import ChartPie from '../jsx/components/charts/Chartjs/pie';

const Risk = () => {
    const tradeHistoryData = [
        {
            id: 1,
            symbol: '0.053138',
            price: "9698.37",
            alert: "200 DMA BreakDown 1D",
            level: "45.63",
            time: "3:06:45 PM",
        },
        {
            id: 2,
            symbol: '0.053138',
            price: "9698.37",
            alert: "200 DMA BreakDown 1D",
            level: "45.63",
            time: "3:06:45 PM",
        },
        {
            id: 3,
            symbol: '0.053138',
            price: "9698.37",
            alert: "200 DMA BreakDown 1D",
            level: "45.63",
            time: "3:06:45 PM",
        },
        {
            id: 4,
            symbol: '0.053138',
            price: "9698.37",
            alert: "200 DMA BreakDown 1D",
            level: "45.63",
            time: "3:06:45 PM",
        },
        {
            id: 5,
            symbol: '0.053138',
            price: "9698.37",
            alert: "200 DMA BreakDown 1D",
            level: "45.63",
            time: "3:06:45 PM",
        },
        {
            id: 6,
            symbol: '0.053138',
            price: "9698.37",
            alert: "200 DMA BreakDown 1D",
            level: "45.63",
            time: "3:06:45 PM",
        },
    ];

    return (
        <Row>
            <Col md={6}>
                <CustomCard title="Risk Overview">
                    <ChartPie height={200} />
                </CustomCard>
            </Col>
            <Col md={6}>
                <CustomCard title="Trading Reports">
                    <div className="d-flex mb-3 align-items-center  justify-content-between">
                        <span>Value Trade</span>
                        <span className='fs-2'>$154,657,645</span>
                    </div>
                    <div className="d-flex align-items-center  justify-content-between">
                        <span>Number of Traders</span>
                        <span className='fs-6'>15,364</span>
                    </div>
                    <div className="d-flex align-items-center  justify-content-between">
                        <span>Average Trade Size</span>
                        <span className='fs-6'>$3,556</span>
                    </div>
                    <div className="d-flex align-items-center  justify-content-between">
                        <span>Average on Book Spread</span>
                        <span className='fs-6'>28.66 BPS</span>
                    </div>
                    <div className="d-flex align-items-center  justify-content-between">
                        <span>Distinct Instruments Traded</span>
                        <span className='fs-6'>597</span>
                    </div>
                </CustomCard>
            </Col>
            <Col md={12}>
                <CustomCard title="Real Time Alert">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Symbol</strong>
                                </th>
                                <th>
                                    <strong>Price</strong>
                                </th>
                                <th>
                                    <strong>Alert</strong>
                                </th>
                                <th>
                                    <strong>Level</strong>
                                </th>
                                <th>
                                    <strong>Time</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tradeHistoryData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.symbol}</td>
                                    <td className="">{item.price}</td>
                                    <td className="text-danger">{item.alert}</td>
                                    <td className="">{item.level}</td>
                                    <td className="">{item.time}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CustomCard>
            </Col>
        </Row>
    );
};

export default Risk;
