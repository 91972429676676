import { ReactComponent as Home } from '../../../images/sidebar-icons/Home.svg';
import { ReactComponent as RiskCompliance } from '../../../images/sidebar-icons/RiskCompliance.svg';
import { ReactComponent as RegulatoryReporting } from '../../../images/sidebar-icons/RegulatoryReporting.svg';
import { ReactComponent as BackTesting } from '../../../images/sidebar-icons/BackTesting.svg';
import { ReactComponent as CustomerSupport } from '../../../images/sidebar-icons/CustomerSupport.svg';
import { ReactComponent as TradeArchiving } from '../../../images/sidebar-icons/TradeArchiving.svg';
import { ReactComponent as TradeConfirmation } from '../../../images/sidebar-icons/TradeConfirmation.svg';
import { ReactComponent as TradeSettlement } from '../../../images/sidebar-icons/TradeSettlement.svg';
import { ReactComponent as TradeClearing } from '../../../images/sidebar-icons/TradeClearing.svg';
import { ReactComponent as MarketDataAnalysis } from '../../../images/sidebar-icons/MarketDataAnalysis.svg';
import { ReactComponent as TradeSimulation } from '../../../images/sidebar-icons/TradeSimulation.svg';
import { ReactComponent as TradeEnrichment } from '../../../images/sidebar-icons/TradeEnrichment.svg';
import { ReactComponent as ComplianceReporting } from '../../../images/sidebar-icons/ComplianceReporting.svg';
import { ReactComponent as Portfolio } from '../../../images/sidebar-icons/Portfolio.svg';
import { ReactComponent as StockLookup } from '../../../images/sidebar-icons/StockLookup.svg';
import { ReactComponent as Orders } from '../../../images/sidebar-icons/Orders.svg';

export const MenuList = [
    //Dashboard
    {
        title: 'Home',
        iconSVG: <Home className="icon-svg" />,
        to: 'dashboard',
    },
    {
        title: 'Portfolio',
        iconSVG: <Portfolio className="icon-svg" />,
        to: 'portfolio',
    },
    {
        title: 'Stock Lookup',
        iconSVG: <StockLookup className="icon-svg" />,
        to: 'stock-lookup',
    },
    {
        title: 'Orders',
        iconSVG: <Orders className="icon-svg" />,
        to: 'orders',
    },
    {
        title: 'Scanner',
        iconSVG: <Home className="icon-svg" />,
        to: 'scanner',
    },
    {
        title: 'Back Testing',
        iconSVG: <BackTesting className="icon-svg" />,
        to: 'back-testing',
    },
    {
        title: 'Risk/Compliance',
        iconSVG: <RiskCompliance className="icon-svg" />,
        to: 'risk-compliance',
    },
    {
        title: 'Market Data Analysis',
        iconSVG: <MarketDataAnalysis className="icon-svg" />,
        to: 'market-data-analysis',
    },
    {
        title: 'Trade Simulation',
        iconSVG: <TradeSimulation className="icon-svg" />,
        to: 'trade-simulation',
    },
    {
        title: 'Trade Archiving',
        iconSVG: <TradeArchiving className="icon-svg" />,
        to: 'trade-archiving',
    },
    {
        title: 'Trade Confirmation',
        iconSVG: <TradeConfirmation className="icon-svg" />,
        to: 'trade-confirmation',
    },
    {
        title: 'Trade Clearing',
        iconSVG: <TradeClearing className="icon-svg" />,
        to: 'trade-cleaning',
    },
    {
        title: 'Settlement',
        iconSVG: <TradeSettlement className="icon-svg" />,
        to: 'settlement',
    },
    {
        title: 'Trade Enrichment',
        iconSVG: <TradeEnrichment className="icon-svg" />,
        to: 'trade-enrichment',
    },
    {
        title: 'Compliance Reporting',
        iconSVG: <ComplianceReporting className="icon-svg" />,
        to: 'compliance-reporting',
    },
    {
        title: 'Regulatory Reporting',
        iconSVG: <RegulatoryReporting className="icon-svg" />,
        to: 'regulatory-reporting',
    },
    {
        title: 'Customer Support',
        iconSVG: <CustomerSupport className="icon-svg" />,
        to: 'customer-support',
    },
];
