import React from 'react';
import { CustomInput } from '../../shared/Inputs/CustomInput';
import { CustomDropdown } from '../../shared/Inputs/CustomDropdown';
import { fundingSourceOptions, yesNoOptions } from '../../utils/dropdownConstants';
import PrimaryButton from '../../shared/Button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveStep, updateFormData } from '../../store1/actions/onBoardingAction';
import formValidation from '../../utils/validations';
import { showFormErrorsRedux } from '../../utils/commonFunctions';

const SecondStep = () => {
    const dispatch = useDispatch();
    const { data, activeStep } = useSelector((state) => state.onBoardForm);

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        dispatch(updateFormData({ [name]: value, formErrors }));
    };

    const handleNext = () => {
        if (showFormErrorsRedux(data, dispatch)) {
            dispatch(setActiveStep(activeStep + 1));
        }
    };

    console.log(data, 'data');
    return (
        <div className="grid col-12 px-2">
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="countryOfTaxResidence"
                data={data}
                onChange={handleChange}
            />
            <CustomDropdown
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="fundingSource"
                data={data}
                onChange={handleChange}
                dropitems={fundingSourceOptions}
            />
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="taxId"
                data={data}
                onChange={handleChange}
            />
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="countryOfBirth"
                data={data}
                onChange={handleChange}
            />
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="countryOfCitizenship"
                data={data}
                onChange={handleChange}
            />
            <CustomDropdown
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="isControlPerson"
                data={data}
                onChange={handleChange}
                dropitems={yesNoOptions}
            />
            <CustomDropdown
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="isAffiliatedExchangeOrFinra"
                data={data}
                onChange={handleChange}
                dropitems={yesNoOptions}
            />

            <CustomDropdown name="isPoliticallyExposed" data={data} onChange={handleChange} dropitems={yesNoOptions} />

            <CustomDropdown
                name="immediateFamilyExposed"
                data={data}
                onChange={handleChange}
                dropitems={yesNoOptions}
            />
            <div className="form-group clearfix text-left w-full text-center mx-2">
                <PrimaryButton className="m-4 text-white" onClick={() => dispatch(setActiveStep(activeStep - 1))}>
                    Back
                </PrimaryButton>
                <PrimaryButton className="text-white" onClick={handleNext}>
                    Next
                </PrimaryButton>
            </div>
        </div>
    );
};

export default SecondStep;
