import formValidation from '../../utils/validations';
import { types } from '../types/types';

export const setActiveStep = (step) => ({
    type: types.SET_ACTIVE_STEP,
    payload: step,
});

export const updateFormData = (data) => ({
    type: types.UPDATE_FORM_DATA,
    payload: data,
});

export const updateAgreementField = ({ index, name, value, formErrors, fieldName }) => ({
    type: types.UPDATE_AGREEMENT_FIELD,
    payload: {
        index,
        name,
        value,
        formErrors,
        fieldName,
    },
});
