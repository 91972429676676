import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { CustomCard } from '../shared/Cards/CustomCards';
import CandlestickChart from '../jsx/components/Dashboard/CandlestickChart/CandlestickChart';

const Orders = () => {
    const tradeHistoryData = [
        {
            id: "1f29l58u1:1 98115",
            instrument: 'CLJ21',
            type: "ICEBERG",
            noofexecutions: "1",
            side: "BUY",
            starttime: "04/15/2021  12:07:26",
            endtime: "04/15/2021  12:07:26",
            exectype: "COMPLETELY_FILLED",
        },
        {
            id: "1f29l58u1:1 98115",
            instrument: 'CLJ21',
            type: "ICEBERG",
            noofexecutions: "1",
            side: "BUY",
            starttime: "04/15/2021  12:07:26",
            endtime: "04/15/2021  12:07:26",
            exectype: "COMPLETELY_FILLED",
        },
        {
            id: "1f29l58u1:1 98115",
            instrument: 'CLJ21',
            type: "ICEBERG",
            noofexecutions: "1",
            side: "BUY",
            starttime: "04/15/2021  12:07:26",
            endtime: "04/15/2021  12:07:26",
            exectype: "COMPLETELY_FILLED",
        },
        {
            id: "1f29l58u1:1 98115",
            instrument: 'CLJ21',
            type: "ICEBERG",
            noofexecutions: "1",
            side: "BUY",
            starttime: "04/15/2021  12:07:26",
            endtime: "04/15/2021  12:07:26",
            exectype: "COMPLETELY_FILLED",
        },
        {
            id: "1f29l58u1:1 98115",
            instrument: 'CLJ21',
            type: "ICEBERG",
            noofexecutions: "1",
            side: "BUY",
            starttime: "04/15/2021  12:07:26",
            endtime: "04/15/2021  12:07:26",
            exectype: "COMPLETELY_FILLED",
        },
        {
            id: "1f29l58u1:1 98115",
            instrument: 'CLJ21',
            type: "ICEBERG",
            noofexecutions: "1",
            side: "BUY",
            starttime: "04/15/2021  12:07:26",
            endtime: "04/15/2021  12:07:26",
            exectype: "COMPLETELY_FILLED",
        },
    ];
    return (
        <Row>
            <Col md={12}>
                <CustomCard title="Orders">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Instrument</strong>
                                </th>
                                <th>
                                    <strong>Id</strong>
                                </th>
                                <th>
                                    <strong>Type</strong>
                                </th>
                                <th>
                                    <strong>No of executions</strong>
                                </th>
                                <th>
                                    <strong>Side</strong>
                                </th>
                                <th>
                                    <strong>Start Time</strong>
                                </th>
                                <th>
                                    <strong>End Time</strong>
                                </th>
                                <th>
                                    <strong>Exec type</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tradeHistoryData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.instrument}
                                    </td>
                                    <td>{item.id}</td>
                                    <td>{item.type}</td>
                                    <td>{item.noofexecutions}</td>
                                    <td>{item.side}</td>
                                    <td>{item.starttime}</td>
                                    <td>{item.endtime}</td>
                                    <td>{item.exectype}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CustomCard>
            </Col>
            <Col xl={12} lg={12}>
                <Card>
                    <Card.Body>
                        <CandlestickChart />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default Orders;
