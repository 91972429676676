import React, { useState } from 'react';
import { Container, Row, Col, Table, Button, Form, InputGroup, FormControl, Card } from 'react-bootstrap';
import CandlestickChart from '../jsx/components/Dashboard/CandlestickChart/CandlestickChart';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const StockMarketApp = () => {
    const [selectedStock, setSelectedStock] = useState(null); // Tracks the selected stock
    const [formType, setFormType] = useState(''); // Tracks the type of form (buy, sell, or details)

    const stockData = [
        { name: "SENSEX", value: "-104.75", current: "18181.75", percentage: "-0.57%", direction: "down" },
        { name: "ASTRON", value: "-104.75", current: "18181.75", percentage: "-0.57%", direction: "down" },
        { name: "SENSEX", value: "-104.75", current: "18181.75", percentage: "-0.57%", direction: "up" },
        { name: "ASTRON", value: "-104.75", current: "18181.75", percentage: "-0.57%", direction: "down" },
    ];

    // Handle stock click and form type selection
    const handleStockClick = (stock, type) => {
        setSelectedStock(stock); // Set the clicked stock as the selected one
        setFormType(type); // Set the type of form (buy, sell, or details)
    };

    // Conditional rendering based on formType
    const renderForm = () => {
        if (!selectedStock) return null;

        if (formType === 'buy') {
            return (
                <div className="border p-3 rounded">
                    <h5>{selectedStock.name}</h5>
                    <p>NSE N/A</p>
                    <Tabs
                        defaultActiveKey="quick"
                        id="uncontrolled-tab-example "
                        className="mb-3 stock-tabs"
                    >
                        <Tab eventKey="quick" title="Quick">
                            <Form>
                                <Form.Group>
                                    <Form.Label>Order Type</Form.Label>
                                    <InputGroup>
                                        <FormControl as="select">
                                            <option>Quick</option>
                                            <option>Regular</option>
                                            <option>Cover</option>
                                            <option>AMO</option>
                                        </FormControl>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Form.Label>Qty.</Form.Label>
                                    <InputGroup>
                                        <FormControl type="number" defaultValue="1" />
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Form.Label>Price</Form.Label>
                                    <InputGroup>
                                        <FormControl type="number" defaultValue={selectedStock.current} />
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Form.Check type="checkbox" label="Intraday" />
                                </Form.Group>

                                <div className='d-flex flex-column gap-2 mt-3'>
                                    <Button className="w-100" variant="primary">
                                        Buy
                                    </Button>
                                    <Button className="w-100" variant="secondary" onClick={() => setSelectedStock(null)}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </Tab>
                        <Tab eventKey="regular" title="Regular">
                            Regular
                        </Tab>
                        <Tab eventKey="cover" title="Cover" disabled>
                            Cover
                        </Tab>
                        <Tab eventKey="amo" title="AMO" disabled>
                            AMO
                        </Tab>
                    </Tabs>
                </div>
            );
        }

        if (formType === 'sell') {
            return (
                <div className="border p-3 rounded">
                    
                    <h5>{selectedStock.name}</h5>
                    <p>NSE N/A</p>
                    <Tabs
                        defaultActiveKey="quick"
                        id="uncontrolled-tab-example "
                        className="mb-3 stock-tabs"
                    >
                        <Tab eventKey="quick" title="Quick">
                        <Form>
                        <Form.Group>
                            <Form.Label>Order Type</Form.Label>
                            <InputGroup>
                                <FormControl as="select">
                                    <option>Quick</option>
                                    <option>Regular</option>
                                    <option>Cover</option>
                                    <option>AMO</option>
                                </FormControl>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Qty.</Form.Label>
                            <InputGroup>
                                <FormControl type="number" defaultValue="1" />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Price</Form.Label>
                            <InputGroup>
                                <FormControl type="number" defaultValue={selectedStock.current} />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Check type="checkbox" label="Intraday" />
                        </Form.Group>

                        <div className='d-flex flex-column gap-2 mt-3'>
                            <Button className="w-100" variant="danger">
                                Sell
                            </Button>
                            <Button className="w-100" variant="secondary" onClick={() => setSelectedStock(null)}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                        </Tab>
                        <Tab eventKey="regular" title="Regular">
                            Regular
                        </Tab>
                        <Tab eventKey="cover" title="Cover" disabled>
                            Cover
                        </Tab>
                        <Tab eventKey="amo" title="AMO" disabled>
                            AMO
                        </Tab>
                    </Tabs>
                    
                </div>
            );
        }

        if (formType === 'details') {
            return (
                <div className="stock-depth-table p-3">
                    {/* Bid-Offer Table */}
                    <Table bordered responsive className="text-center mb-0">
                        <thead>
                            <tr>
                                <th>BID</th>
                                <th>ORDERS</th>
                                <th>QTY.</th>
                                <th>OFFER</th>
                                <th>ORDERS</th>
                                <th>QTY.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-primary">0.00</td>
                                <td>0</td>
                                <td>0</td>
                                <td className="text-danger">3092.45</td>
                                <td>3</td>
                                <td>29</td>
                            </tr>
                            <tr>
                                <td className="text-primary">0.00</td>
                                <td>0</td>
                                <td>0</td>
                                <td className="text-danger">0.00</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td className="text-primary">0.00</td>
                                <td>0</td>
                                <td>0</td>
                                <td className="text-danger">0.00</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td className="text-primary">0.00</td>
                                <td>0</td>
                                <td>0</td>
                                <td className="text-danger">0.00</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td className="text-primary">0.00</td>
                                <td>0</td>
                                <td>0</td>
                                <td className="text-danger">0.00</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr className="text-primary">
                                <td>Total</td>
                                <td>0</td>
                                <td>0</td>
                                <td className="text-danger">Total</td>
                                <td>0</td>
                                <td>29</td>
                            </tr>
                        </tbody>
                    </Table>

                    {/* Depth View */}
                    <div className="text-center mt-2 border-top border-bottom">
                        <Button variant="link" className="text-primary">View 20 depth</Button>
                    </div>

                    {/* Market Summary */}
                    <Row className="mt-3">
                        <Col md={6}>
                            <p className="d-flex justify-content-between"><span className="text-white font-bold">Open </span><span>3136.00</span></p>
                        </Col>
                        <Col md={6}>
                            <p className="d-flex justify-content-between"><span className="text-white font-bold">High </span><span> 3147.95</span></p>
                        </Col>
                        <Col md={6}>
                            <p className="d-flex justify-content-between"><span className="text-white font-bold">Prev. Close </span><span> 3138.10</span></p>
                        </Col>
                        <Col md={6}>
                            <p className="d-flex justify-content-between"><span className="text-white font-bold">Low </span><span> 3075.55</span></p>
                        </Col>
                        <Col md={6}>
                            <p className="d-flex justify-content-between"><span className="text-white font-bold">Volume </span><span> 8,35,371</span></p>
                        </Col>
                        <Col md={6}>
                            <p className="d-flex justify-content-between"><span className="text-white font-bold">Avg. Price </span><span> 3100.01</span></p>
                        </Col>
                        <Col md={6}>
                            <p className="d-flex justify-content-between"><span className="text-white font-bold">LTQ </span><span> 25</span></p>
                        </Col>
                        <Col md={6}>
                            <p className="d-flex justify-content-between"><span className="text-white font-bold">LTT </span><span> 2023-05-17 15:55:29</span></p>
                        </Col>
                        <Col md={6}>
                            <p className="d-flex justify-content-between"><span className="text-white font-bold">Lower Circuit </span><span> 2824.30</span></p>
                        </Col>
                        <Col md={6}>
                            <p className="d-flex justify-content-between"><span className="text-white font-bold">Upper Circuit </span><span> 3451.90</span></p>
                        </Col>
                    </Row>
                </div>
            );
        }

        if (formType === 'chart') {
            return (
                <Card>
                    <Card.Body>
                        <CandlestickChart />
                    </Card.Body>
                </Card>
            );
        }

        return null;
    };

    return (
        <Container fluid>
            <Row>
                {/* Left Side - Stock List */}
                <Col md={selectedStock ? 6 : 12}> {/* Full width if no stock selected, otherwise half width */}
                    <div className='card rounded w-100 overflow-hidden'>
                        <Form.Group className="w-100 mb-3" controlId="search">
                            <FormControl type="text" placeholder="Search" className='bg-transparent' />
                        </Form.Group>
                        <Table className='w-100' responsive bordered hover>
                            <tbody>
                                {stockData.map((stock, index) => (
                                    <tr
                                        key={index}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <td className={stock.name === "SENSEX" ? "text-danger" : "text-success"}>
                                            {stock.name} <span className="small">INDEX</span>
                                        </td>
                                        <td className='text-dark'>{stock.value}</td>
                                        <td className={stock.direction === "down" ? "text-danger" : "text-success"}>
                                            {stock.current}
                                        </td>
                                        <td className='text-dark'>{stock.percentage}</td>
                                        <td className='d-flex gap-2'>
                                            <Button variant="primary" size="sm" onClick={() => handleStockClick(stock, 'buy')}>B</Button>
                                            <Button variant="danger" size="sm" onClick={() => handleStockClick(stock, 'sell')}>S</Button>
                                            <Button variant="outline-secondary" size="sm" onClick={() => handleStockClick(stock, 'details')}>
                                                <i className="fa fa-align-center" aria-hidden="true"></i>
                                            </Button>
                                            <Button variant="dark" size="sm" onClick={() => handleStockClick(stock, 'chart')}>
                                                <i class="fa fa-line-chart" aria-hidden="true"></i>

                                            </Button>
                                            <Button variant="outline-secondary" size="sm" >
                                                <i class="fa fa-trash" aria-hidden="true"></i>

                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>

                {/* Right Side - Dynamic Form based on button clicked */}
                {selectedStock && (
                    <Col md={6}> {/* Only show the second column when stock is selected */}
                        {renderForm()}
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export default StockMarketApp;
