import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { CustomCard } from '../shared/Cards/CustomCards';
import CandlestickChart from '../jsx/components/Dashboard/CandlestickChart/CandlestickChart';

const TradeConfirmation = () => {
    const tradeHistoryData = [
        {
            id: 1,
            symbol: 'AAPL',
            price: "1.1825",
            status: "-0.0005",
        },
       
    ];
    return (
        <Row>
            <Col md={12}>
                <CustomCard title="Trade Summary Overview">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Price</strong>
                                </th>
                                <th>
                                    <strong>Quantity</strong>
                                </th>
                                <th>
                                    <strong>Venue</strong>
                                </th>
                                <th>
                                    <strong>Strategy</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tradeHistoryData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.symbol}</td>
                                    <td className="">{item.price}</td>
                                    <td className="text-danger">{item.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CustomCard>
            </Col>
            <Col md={12}>
                <CustomCard title="Market Execution Results">
                    <CandlestickChart />
                </CustomCard>
            </Col>
        </Row>
    );
};

export default TradeConfirmation;
