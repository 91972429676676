export const taxIdTypeOptions = [
    {
        name: 'USA Social Security Number',
        value: 'USA_SSN',
    },
    {
        name: 'Argentina CUIT',
        value: 'ARG_AR_CUIT',
    },
    {
        name: 'Australian Tax File Number',
        value: 'AUS_TFN',
    },
    {
        name: 'Australian Business Number',
        value: 'AUS_ABN',
    },
    {
        name: 'Bolivia NIT',
        value: 'BOL_NIT',
    },
    {
        name: 'Brazil CPF',
        value: 'BRA_CPF',
    },
    {
        name: 'Brazil CPF',
        value: 'BRA_CPF',
    },
    {
        name: 'Chile RUT',
        value: 'CHL_RUT',
    },
    {
        name: 'Colombia NIT',
        value: 'COL_NIT',
    },
    {
        name: 'Costa Rica NITE',
        value: 'CRI_NITE',
    },
];
export const fundingSourceOptions = [
    {
        name: 'Employeement Income',
        value: 'employment_income',
    },
    {
        name: 'Investments',
        value: 'investments',
    },
    {
        name: 'Inheritance',
        value: 'inheritance',
    },
    {
        name: 'Business Income',
        value: 'business_income',
    },
    {
        name: 'Savings',
        value: 'savings',
    },
    {
        name: 'Family',
        value: 'family',
    },
];
export const yesNoOptions = [
    {
        name: 'True',
        value: true,
    },
    {
        name: 'False',
        value: false,
    },
];
export const agreementOptions = [
    {
        name: 'Alpaca Margin Agreement',
        value: 'margin_agreement',
    },
    {
        name: 'Alpaca Account Agreement',
        value: 'account_agreement',
    },
    {
        name: 'Alpaca Customer Agreement',
        value: 'customer_agreement',
    },
];
