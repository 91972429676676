import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { CustomCard } from '../shared/Cards/CustomCards';
import BarChart5 from '../jsx/components/charts/Chartjs/bar5';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';

const TradeArchiving = () => {
    const tradeHistoryData = [
        {
            id: '#84668340',
            date: '09-03-2024',
            country: "Counterparty A",
            period: "2 months",
            archivedate: "09-03-2024",
            status: "Failed",
        },
        
    ];
    const ApexLine2 = loadable(() => pMinDelay(import('./../jsx/components/Dashboard/LineChart/Line2'), 1000));
    return (
        <Row>
            <Col md={12}>
                <CustomCard title="Trade Records Archive">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Trade ID</strong>
                                </th>
                                <th>
                                    <strong>Trade Date</strong>
                                </th>
                                <th>
                                    <strong>Counterparty</strong>
                                </th>
                                <th>
                                    <strong>Retention Period</strong>
                                </th>
                                <th>
                                    <strong>Archive Date</strong>
                                </th>
                                <th>
                                    <strong>Status</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tradeHistoryData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td className="">{item.date}</td>
                                    <td className="">{item.country}</td>
                                    <td className="">{item.period}</td>
                                    <td className="">{item.archivedate}</td>
                                    <td className="">{item.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CustomCard>
            </Col>
            <Col md={12}>
                <CustomCard title="Retention Status">
                    <ApexLine2 />
                </CustomCard>
            </Col>
            <Col md={12}>
                <CustomCard title="Compliance Status">
                    <BarChart5 />
                </CustomCard>
            </Col>
        </Row>
    );
};

export default TradeArchiving;
