export const types = {
    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER',
    SHOW_TOAST: 'SHOW_TOAST',
    PROFILE: 'PROFILE',
    SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
    UPDATE_FORM_DATA: 'UPDATE_FORM_DATA',
    UPDATE_AGREEMENT_FIELD: 'UPDATE_AGREEMENT_FIELD',
    UPDATE_FORM_ERRORS: 'UPDATE_FORM_ERRORS',
    RESET_FORM: 'RESET_FORM',
};
