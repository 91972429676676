import React from 'react';
import InputLayout from '../InputLayout';
export const CustomInput = ({
    label,
    name,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    required,
    col = 4,
    inputClass,
    disabled = false,
    type = 'text',
    customIndex,
    fieldName,
    ...props
}) => {
    return (
        <InputLayout
            col={col || 4}
            label={label}
            name={name}
            required={required}
            extraClassName={extraClassName}
            data={data}
            errorMessage={errorMessage}
        >
            <input
                id={name}
                name={name}
                value={value || data?.[name] || ''}
                type={type}
                onChange={(e) =>
                    onChange &&
                    onChange({ ...e, name: e.target.name, value: e.target.value, index: customIndex, fieldName })
                }
                className={`form-control mb-2 ${inputClass ? inputClass : ''} ${errorMessage ? 'p-invalid' : ''}`}
                disabled={disabled}
                {...props}
            />
        </InputLayout>
    );
};
