import React from 'react';
import { CustomCard } from '../shared/Cards/CustomCards';
import { CustomDropdown } from '../shared/Inputs/CustomDropdown';
import { Col, Row } from 'react-bootstrap';
import PrimaryButton from '../shared/Button/CustomButton';
import { useNavigate } from 'react-router-dom';

const BackTesting = () => {
    const navigate = useNavigate();  // Initialize useNavigate

    const handleRunTesting = () => {
        navigate('/run-testing');  
    };
    return (<div className='row'>
        <div className="col-md-6">
            <CustomCard title="DataSet">
                <CustomDropdown col={12} label="Select" dropdownlabel="Dow 30 (current) public" />
            </CustomCard>
        </div>
        <div className="col-md-6">
            <CustomCard title="Data Range & Scale">
                <CustomDropdown col={12} label="Scale" dropdownlabel="Daily" />
            </CustomCard>
        </div>
        <div className="col-md-12 mb-5">
            <CustomCard title="Position Sizing">
                <Row>
                    <Col md={6} className='mb-2'>
                        <CustomDropdown col={12} label="Starting Capital" dropdownlabel="100000" />
                    </Col>
                    <Col md={6} className='mb-2'>
                        <CustomDropdown col={12} label="Benchmark Symbol" dropdownlabel="QQQ" />
                    </Col>
                    <Col md={6} className='mb-2'>
                        <CustomDropdown col={12} label="Margin Factor" dropdownlabel="2:1" />
                    </Col>
                    <Col md={6} className='mb-2'>
                        <CustomDropdown col={12} label="Sizing Method" dropdownlabel="Percent of Equity" />
                    </Col>
                    <Col md={6} className='mb-2'>
                        <CustomDropdown col={12} label="Percent" dropdownlabel="20" />
                    </Col>
                    <Col md={6} className='mb-2'>
                        <CustomDropdown col={12} label="Base Currency" dropdownlabel="USD" />
                    </Col>
                </Row>
            </CustomCard>
            <div className="d-flex justify-content-center">
                <PrimaryButton className="px-4 p-2  " onClick={handleRunTesting}>Run Back Testing</PrimaryButton>
            </div>
        </div>
    </div>);
};

export default BackTesting;
