import React from 'react';
import InputLayout from '../InputLayout';
import { Dropdown } from 'react-bootstrap';
export const CustomDropdown = ({
    label,
    name,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    required,
    col = 4,
    inputClass,
    disabled = false,
    dropdownlabel,
    dropitems,
    type = 'text',
    customIndex,
    fieldName,
    ...props
}) => {
    return (
        <InputLayout
            col={col}
            label={label}
            name={name}
            required={required}
            extraClassName={extraClassName}
            data={data}
            errorMessage={errorMessage}
        >
            <Dropdown className="w-100">
                <Dropdown.Toggle className="w-100 bg-transparent border-primary text-start" id="dropdown-basic">
                    {dropdownlabel || dropitems.find((item) => item.value === data?.[name])?.name || 'Select an option'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {dropitems?.map((option, index) => (
                        <Dropdown.Item
                            key={index}
                            onClick={() => onChange({ name, value: option.value, index: customIndex, fieldName })}
                        >
                            {option.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </InputLayout>
    );
};
