import React from 'react';

import EventCalendar from './EventCalendar';

const Calendar = () => {
    return (
        <div className="h-80">
            {/* <PageTitle activeMenu="Calerdar" motherMenu="App" /> */}

            <EventCalendar />
            {/* <EventCalendar /> */}
        </div>
    );
};

export default Calendar;
