import React, { useState } from 'react';
import { CustomGridLayout } from '../../shared/Cards/CustomCards';
import { CustomDropdown } from '../../shared/Inputs/CustomDropdown';
import { agreementOptions } from '../../utils/dropdownConstants';
import { CustomInput } from '../../shared/Inputs/CustomInput';
import PrimaryButton from '../../shared/Button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveStep, updateAgreementField, updateFormData } from '../../store1/actions/onBoardingAction';
import { onBoardingClient } from '../../store1/actions/userActions';
import { useNavigate } from 'react-router-dom';
import formValidation from '../../utils/validations';
import { showArrayFormErrors } from '../../utils/commonFunctions';

const ThirdStep = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { data, activeStep } = useSelector((state) => state.onBoardForm);
    const navigate = useNavigate();

    // const handleChangeDynamicField = ({ name, value, index }) => {
    //     dispatch(updateAgreementField(index, name, value));
    // };
    const handleChangeDynamicField = ({ name, value, index, fieldName }) => {
        const formErrors = formValidation(name, value, data[fieldName][index]);
        dispatch(updateAgreementField({ index, name, value, formErrors, fieldName }));
    };

    const handleSubmit = () => {
        let validatedSchedule = showArrayFormErrors(data.agreements);

        console.log(data, 'validatedSchedule');
        if (!validatedSchedule.isValid) {
            dispatch(
                updateFormData({
                    agreements: validatedSchedule.data,
                }),
            );
        }
        if (validatedSchedule.isValid) {
            dispatch(onBoardingClient(data, setLoading, navigate));
        }
    };

    console.log(data, 'data');
    // const handleChangeDynamicField = ({ name, value, customIndex, fieldName }) => {
    //     const _newData = { ...data };
    //     let obj = _newData[fieldName][customIndex];
    //     obj[name] = value;
    //     const formErrors = formValidation(name, value, obj);
    //     obj.formErrors = formErrors;
    //     _newData[fieldName][customIndex] = obj;
    //     setData(() => ({ ..._newData }));
    // };
    const getFilteredOptions = (selectedAgreements, currentIndex) => {
        console.log(selectedAgreements, currentIndex, 'currentIndex');
        return agreementOptions.filter(
            (option) =>
                !selectedAgreements.some(
                    (agreement, index) => index !== currentIndex && agreement.agreement === option.value,
                ),
        );
    };
    return (
        <>
            {data?.agreements?.map((agreement, index) => (
                <div key={index}>
                    <CustomGridLayout extraClass="align-items-center">
                        <CustomDropdown
                            extraClassName="text-start"
                            inputClass="border-dark text-black-alpha-90"
                            col={4}
                            name="agreement"
                            data={agreement}
                            onChange={handleChangeDynamicField}
                            dropitems={getFilteredOptions(data.agreements, index)}
                            customIndex={index}
                            fieldName="agreements"
                        />
                        <CustomInput
                            extraClassName="text-start"
                            inputClass="border-dark text-black-alpha-90"
                            col={4}
                            name="signedAt"
                            data={agreement}
                            onChange={handleChangeDynamicField}
                            type="date"
                            customIndex={index}
                            fieldName="agreements"
                        />
                        <CustomInput
                            extraClassName="text-start"
                            inputClass="border-dark text-black-alpha-90"
                            col={4}
                            name="ipAddress"
                            data={agreement}
                            onChange={handleChangeDynamicField}
                            customIndex={index}
                            fieldName="agreements"
                        />
                    </CustomGridLayout>
                </div>
            ))}
            <div className="form-group clearfix text-left w-full text-center mx-2">
                <PrimaryButton className="m-4 text-white" onClick={() => dispatch(setActiveStep(activeStep - 1))}>
                    Back
                </PrimaryButton>
                <PrimaryButton className="text-white" onClick={handleSubmit}>
                    Next
                </PrimaryButton>
            </div>
        </>
    );
};

export default ThirdStep;
