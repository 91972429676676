import React, { Fragment, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import PageTitle from '../../../layouts/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, onGetOtpverify, onTwoFactorAuthChange } from '../../../../store1/actions/userActions';
import CustomInputSwitch from '../../../../shared/Inputs/CustomInputSwitch';
import PrimaryButton from '../../../../shared/Button/CustomButton';
import { useNavigate } from 'react-router-dom';
import { CustomInput } from '../../../../shared/Inputs/CustomInput';
import formValidation from '../../../../utils/validations';
import { showFormErrors } from '../../../../utils/commonFunctions';

const AppProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    const { user } = useSelector((state) => state?.user);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        open: false,
        two_factor_auth: false,
        otpModal: false,
        email: '',
        otpCode: '',
        purpose: 'TWO_FACTOR',
    });

    useEffect(() => {
        setData((prev) => ({ ...prev, two_factor_auth: user?.two_factor_auth, email: user?.email }));
    }, [user]);

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleSentOtp = () => {
        if (showFormErrors(data, setData, ['otpCode'])) {
            dispatch(
                onGetOtpverify(data, setLoading, () => setData((prev) => ({ ...prev, open: false, otpModal: true }))),
            );
        }
    };

    const handleOk = () => {
        if (showFormErrors(data, setData)) {
            dispatch(
                onTwoFactorAuthChange(data, setLoading, () => {
                    dispatch(getProfile());
                    setData((prev) => ({ ...prev, otpModal: false, otpCode: '' }));
                }),
            );
        }
    };

    console.log('user>>', user, data);
    return (
        <Fragment>
            <PageTitle activeMenu="Profile" motherMenu="App" />

            <div className="row">
                <div className="col-xl-12 col-lg-8">
                    <div className="card profile-card card-bx m-b30">
                        <div className="card-header">
                            <h6 className="title">Account setup</h6>
                            <PrimaryButton onClick={() => navigate('/edit-profile')}>Edit Profile</PrimaryButton>
                        </div>
                        <form className="profile-form">
                            <div className="card-body">
                                <div className="row">
                                    <CustomInputSwitch
                                        label="Two Step Verification"
                                        name="two_factor_auth"
                                        data={data}
                                        onChange={() => setData((prev) => ({ ...prev, open: true }))}
                                    />
                                    <div className="col-sm-6 m-b30">
                                        <label className="form-label">Name</label>
                                        <p>{user?.first_name ? user?.first_name + ' ' + user?.last_name : '-'}</p>
                                    </div>

                                    <div className="col-sm-6 m-b30">
                                        <label className="form-label">Email address</label>
                                        <p>{user?.email ? user?.email : '-'}</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Auth Modal */}
            <Modal
                show={data.open}
                className="modal fade"
                id="replyModal"
                onHide={() => setData((prev) => ({ ...prev, open: false }))}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Two Factor Verification</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setData((prev) => ({ ...prev, open: false }))}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <h4>
                            Would you like to {user?.two_factor_auth ? 'disable' : 'enable'} Two-Factor Authentication ?
                        </h4>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-danger light"
                            onClick={(prev) => setData((prev) => ({ ...prev, open: false }))}
                        >
                            Close
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={handleSentOtp}>
                            OK
                        </button>
                    </div>
                </div>
            </Modal>

            {/* Otp Modal */}
            <Modal
                show={data.otpModal}
                className="modal fade"
                id="replyModal"
                onHide={() => setData((prev) => ({ ...prev, otpModal: false }))}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">OTP Verification</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setData((prev) => ({ ...prev, otpModal: false }))}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <h4>
                            You'll need to verify your identity. We've sent a verification code to &nbsp;
                            {data?.email}
                        </h4>
                        <CustomInput name="otpCode" data={data} onChange={handleChange} />
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-danger light"
                            onClick={(prev) => setData((prev) => ({ ...prev, otpModal: false }))}
                        >
                            Close
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={handleOk}>
                            OK
                        </button>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default AppProfile;
