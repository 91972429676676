import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import ChartPie from '../jsx/components/charts/Chartjs/pie';
import { CustomCard } from '../shared/Cards/CustomCards';
import { Link } from 'react-router-dom';
import TradeClearingBarChart from './TradeClearingBarChart';
import TradeClearingHeatmap from './TradeClearingHeatmap';

const TradeCleaning = () => {

    const settlementTrackerData = [
        {
            id: 1,
            clearedTrades: "150",
            clearingTime: "2 hours",
            failedTrades: "5",
            nettingEfficiency: "85%",
        },
        {
            id: 2,
            clearedTrades: "150",
            clearingTime: "2 hours",
            failedTrades: "5",
            nettingEfficiency: "85%",
        },
        {
            id: 3,
            clearedTrades: "150",
            clearingTime: "2 hours",
            failedTrades: "5",
            nettingEfficiency: "85%",
        },
        {
            id: 4,
            clearedTrades: "150",
            clearingTime: "2 hours",
            failedTrades: "5",
            nettingEfficiency: "85%",
        },
        {
            id: 5,
            clearedTrades: "150",
            clearingTime: "2 hours",
            failedTrades: "5",
            nettingEfficiency: "85%",
        },
        {
            id: 6,
            clearedTrades: "150",
            clearingTime: "2 hours",
            failedTrades: "5",
            nettingEfficiency: "85%",
        },
    ];

    const overviewOptions = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: false,
        },

        legend: {
            show: true,
            fontSize: '12px',
            fontWeight: 300,

            labels: {
                colors: 'black',
            },
            position: 'top',
            horizontalAlign: 'left',
            markers: {
                width: 19,
                height: 19,
                strokeWidth: 0,
                radius: 19,
                strokeColor: '#fff',
                fillColors: ['#fc8019', '#9568ff', '#17a2b8'],
                offsetX: 0,
                offsetY: 0,
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#3e4954',
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 100,
                },
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        fill: {
            colors: ['#fc8019', '#9568ff', '#17a2b8'],
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return '$ ' + val + ' thousands';
                },
            },
        },
    }

    const overviewSeries = [
        {
            name: 'Cleared Trades',
            data: [320, 250, 450, 120, 400, 330, 120, 500, 320, 230, 450, 170],
        },
        {
            name: 'Pending Trades',
            data: [170, 350, 101, 290, 330, 230, 450, 520, 440, 180, 550, 330],
        },
        {
            name: 'Failed Trades',
            data: [170, 350, 101, 290, 400, 200, 190, 340, 430, 230, 120, 430],
        },
    ]

    const tradeClearingBarOptions = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: false,
        },

        legend: {
            show: true,
            fontSize: '12px',
            fontWeight: 300,

            labels: {
                colors: 'black',
            },
            position: 'top',
            horizontalAlign: 'center',
            markers: {
                width: 19,
                height: 19,
                strokeWidth: 0,
                radius: 19,
                strokeColor: '#fff',
                fillColors: ['#fc8019', '#9568ff'],
                offsetX: 0,
                offsetY: 0,
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#3e4954',
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 100,
                },
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: ['Counterparty A', 'Counterparty B', 'Counterparty C', 'Counterparty D'],
        },
        fill: {
            colors: ['#fc8019', '#9568ff'],
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return '$ ' + val + ' thousands';
                },
            },
        },
    }

    const tradeClearingBarSeries = [
        {
            name: 'Netted Obligations',
            data: [320, 250, 450, 120],
        },
        {
            name: 'Non-Netted Obligations',
            data: [170, 350, 101, 290],
        },
    ]

    const heatmapOptions = {
        chart: {
            height: 450,
            type: 'heatmap',
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#000'], // Black color for data labels
                fontSize: '12px',
            },
            formatter: function (val) {
                return `${val}%`; // Append percentage symbol to values
            },
        },
        colors: ['#fc8019', '#9568ff', '#17a2b8', '#ff4560'], // Updated to include more color options for four types
        xaxis: {
            type: 'category',
            categories: [100, 150, 200, 250, 300, 350, 400], // X-axis values
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#3e4954',
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 100,
                },
            },
            categories: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'], // Y-axis values
        },
        grid: {
            padding: {
                right: 20,
            },
        },
        legend: {
            show: true,
            position: 'top', // Legend at the top-left corner
            horizontalAlign: 'left', // Align left
            labels: {
                colors: '#ffffff', // White legend text color
            },
        },
        plotOptions: {
            heatmap: {
                colorScale: {
                    ranges: [
                        {
                            from: 100,
                            to: 150,
                            color: '#00A100', // Green
                            name: 'Counterparty A', // First counterparty
                        },
                        {
                            from: 151,
                            to: 200,
                            color: '#128FD9', // Blue
                            name: 'Counterparty B', // Second counterparty
                        },
                        {
                            from: 201,
                            to: 300,
                            color: '#FFB200', // Yellow
                            name: 'Counterparty C', // Third counterparty
                        },
                        {
                            from: 301,
                            to: 400,
                            color: '#FF4560', // Red
                            name: 'Counterparty D', // Fourth counterparty
                        },
                    ],
                },
            },
        },
    };



    const heatmapData = [
        {
            name: 'Day 7',
            data: [120, 150, 170, 200, 220, 250, 300],
        },
        {
            name: 'Day 6',
            data: [130, 160, 180, 210, 230, 270, 320],
        },
        {
            name: 'Day 5',
            data: [140, 170, 190, 220, 250, 300, 350],
        },
        {
            name: 'Day 4',
            data: [140, 170, 190, 220, 250, 300, 350],
        },
        {
            name: 'Day 3',
            data: [140, 170, 190, 220, 250, 300, 350],
        },
        {
            name: 'Day 2',
            data: [140, 170, 190, 220, 250, 300, 350],
        },
        {
            name: 'Day 1',
            data: [140, 170, 190, 220, 250, 300, 350],
        },
    ];

    return (
        <>
            <Col xl={12} lg={12}>
                <Card>
                    <Card.Header>
                        <h4 className="card-title">Overview</h4>
                    </Card.Header>
                    <Card.Body>
                        <TradeClearingBarChart series={overviewSeries} options={overviewOptions} />
                    </Card.Body>
                </Card>
            </Col>

            <Row className='flex'>
                <Col xl={4} lg={4}>
                    <Card>
                        <Card.Header>
                            <h4 className="card-title">Netting Status</h4>
                        </Card.Header>
                        <Card.Body>
                            <TradeClearingBarChart series={tradeClearingBarSeries} options={tradeClearingBarOptions} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4} md={4}>
                    <CustomCard title="Settlement Progress">
                        <ChartPie height={200} />
                    </CustomCard>
                </Col>

                <Col md={4} className='widget-timeline dz-scroll style-1'>
                    <CustomCard title="Margin Call Alerts">
                        <ul className="timeline ">
                            <li>
                                <div className="timeline-badge primary" />
                                <Link
                                    className="timeline-panel c-pointer text-muted"
                                    to="#"
                                >
                                    <span>Margin Call Alert: Trade 256</span>
                                    <h6 className="mb-0">
                                        (Shortfall $10K) - URGENT
                                    </h6>
                                </Link>
                            </li>
                            <li>
                                <div className="timeline-badge info"></div>
                                <Link
                                    className="timeline-panel c-pointer text-muted"
                                    to="#"
                                >
                                    <span>Margin Call Alert: Trade 256</span>
                                    <h6 className="mb-0">
                                        (Shortfall $10K) - URGENT
                                    </h6>
                                </Link>
                            </li>
                            <li>
                                <div className="timeline-badge danger"></div>
                                <Link
                                    className="timeline-panel c-pointer text-muted"
                                    to="#"
                                >
                                    <span>Margin Call Alert: Trade 256</span>
                                    <h6 className="mb-0">
                                        (Shortfall $10K) - URGENT
                                    </h6>
                                </Link>
                            </li>
                            <li>
                                <div className="timeline-badge success"></div>
                                <Link
                                    className="timeline-panel c-pointer text-muted"
                                    to="#"
                                >
                                    <span>Margin Call Alert: Trade 256</span>
                                    <h6 className="mb-0">
                                        (Shortfall $10K) - URGENT
                                    </h6>
                                </Link>
                            </li>
                            <li>
                                <div className="timeline-badge warning"></div>
                                <Link
                                    className="timeline-panel c-pointer text-muted"
                                    to="#"
                                >
                                    <span>Margin Call Alert: Trade 256</span>
                                    <h6 className="mb-0">
                                        (Shortfall $10K) - URGENT
                                    </h6>
                                </Link>
                            </li>

                        </ul>
                    </CustomCard>
                </Col>
            </Row>

            <Col xl={12} lg={12}>
                <Card>
                    <Card.Header>
                        <h4 className="card-title">Risk Management Tools</h4>
                    </Card.Header>
                    <Card.Body>
                        <TradeClearingHeatmap options={heatmapOptions} data={heatmapData} />
                    </Card.Body>
                </Card>
            </Col>

            <Col xl={12} lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Performance Metrics & Analytics</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Cleared trades</strong>
                                    </th>
                                    <th>
                                        <strong>Average Clearing Time</strong>
                                    </th>
                                    <th>
                                        <strong>Number of failed trades</strong>
                                    </th>
                                    <th>
                                        <strong>Netting Efficiency</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {settlementTrackerData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.clearedTrades}</td>
                                        <td >{item.clearingTime}</td>
                                        <td >{item.failedTrades}</td>
                                        <td >{item.nettingEfficiency}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default TradeCleaning;
