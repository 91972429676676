import React, { Component } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Alert from 'sweetalert2';

class EventCalendar extends Component {
    state = {
        calendarEvents: this.generateWeeklyEvents(), // Generate dynamic events for the current week
        searchQuery: '',
        view: 'timeGridWeek', // Default view is weekly layout
    };

    /**
     * Generates events dynamically based on the current week
     */
    generateWeeklyEvents() {
        const events = [];
        const today = new Date();
        const startOfWeek = this.getStartOfWeek(today); // Get the start of the current week

        // Generate 7 days of events for the current week
        for (let i = 0; i < 7; i++) {
            const eventDate = new Date(startOfWeek);
            eventDate.setDate(startOfWeek.getDate() + i);

            events.push(
                {
                    title: `Morning Event`,
                    start: new Date(eventDate.setHours(9, 0, 0)).toISOString(),
                    id: `morning-${i + 1}`,
                },
                {
                    title: `Afternoon Event`,
                    start: new Date(eventDate.setHours(14, 0, 0)).toISOString(),
                    id: `afternoon-${i + 1}`,
                },
                {
                    title: `Evening Event`,
                    start: new Date(eventDate.setHours(18, 0, 0)).toISOString(),
                    id: `evening-${i + 1}`,
                },
            );
        }

        return events;
    }

    /**
     * Get the start of the current week (Sunday)
     */
    getStartOfWeek(date) {
        const day = date.getDay(); // Get the day (0 = Sunday, 1 = Monday, etc.)
        const diff = date.getDate() - day; // Adjust to the nearest Sunday
        return new Date(date.setDate(diff));
    }

    /**
     * Handles event click to show details
     */
    eventClick = (eventClick) => {
        Alert.fire({
            title: eventClick.event.title,
            html:
                `<div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>Title</td>
                                <td><strong>` +
                eventClick.event.title +
                `</strong></td>
                            </tr>
                            <tr>
                                <td>Start Time</td>
                                <td><strong>` +
                eventClick.event.start +
                `</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>`,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Remove Event',
            cancelButtonText: 'Close',
        }).then((result) => {
            if (result.value) {
                eventClick.event.remove();
                Alert.fire('Deleted!', 'Your event has been deleted.', 'success');
            }
        });
    };

    /**
     * Handles search query input change
     */
    handleSearchChange = (e) => {
        this.setState({ searchQuery: e.target.value });
    };

    /**
     * Filters events based on the search query
     */
    getFilteredEvents = () => {
        const { searchQuery, calendarEvents } = this.state;
        if (!searchQuery) {
            return calendarEvents;
        }
        return calendarEvents.filter((event) => event.title.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    /**
     * Handles view change
     */
    handleViewChange = (view) => {
        this.setState({ view });
    };

    render() {
        return (
            <div className="animated fadeIn demo-app">
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <div className="demo-app-calendar mt-3" id="mycalendartest">
                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        initialView={this.state.view} // Set dynamic view
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'dayGridMonth,timeGridWeek,dayGridDay',
                                            right: 'title',
                                        }}
                                        events={this.getFilteredEvents()} // Pass filtered events to the calendar
                                        eventClick={this.eventClick}
                                        editable={true}
                                        droppable={true}
                                        selectable={true}
                                        allDaySlot={false}
                                        slotMinTime="05:00:00"
                                        slotMaxTime="24:00:00"
                                        //  scrollTime="07:00:00" // Start view at 7 AM
                                        //  height="auto" // Make the calendar scrollable
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default EventCalendar;
