import AppProfile from '../jsx/components/AppsMenu/AppProfile/AppProfile';
import EditProfile from '../jsx/components/AppsMenu/AppProfile/EditProfile';
import BackTesting from '../views/BackTesting';
import ComplainceReporting from '../views/ComplainceReporting';
import CustomerSupport from '../views/CustomerSupport';
import Dashboard from '../views/Dashboard/Dashboard';
import MarketDataAnalysis from '../views/MarketDataAnalysis';
import Orders from '../views/Orders';
import Portfolio from '../views/Portfolio';
import RegulatoryReporting from '../views/RegulatoryReporting';
import Risk from '../views/Risk';
import RunTesting from '../views/RunTesting';
import Scanner from '../views/Scanner';
import Settlement from '../views/Settlement';
import StockLookup from '../views/StockLookup';
import TradeArchiving from '../views/TradeArchiving';
import TradeCleaning from '../views/TradeCleaning';
import TradeConfirmation from '../views/TradeConfirmation';
import TradeEnrichment from '../views/TradeEnrichment';
import TradeSimulation from '../views/TradeSimulation';

export const PrivateRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        element: <Dashboard />,
    },
    {
        path: '/portfolio',
        name: 'Portfolio',
        element: <Portfolio />,
    },
    {
        path: '/stock-lookup',
        name: 'Stock Lookup',
        element: <StockLookup />,
    },
    {
        path: '/orders',
        name: 'Orders',
        element: <Orders />,
    },
    {
        path: '/scanner',
        name: 'Scanner',
        element: <Scanner />,
    },
    {
        path: '/profile',
        name: 'Profile Settings',
        element: <AppProfile />,
    },
    {
        path: '/edit-profile',
        name: 'Edit Profile',
        element: <EditProfile />,
    },
    {
        path: '/back-testing',
        name: 'Back Testing',
        element: <BackTesting />,
    },
    {
        path: '/run-testing',
        name: 'Run Testing',
        element: <RunTesting />,
    },
    {
        path: '/risk-compliance',
        name: 'Risk Compliance',
        element: <Risk />,
    },
    {
        path: '/market-data-analysis',
        name: 'Market Data Analysis',
        element: <MarketDataAnalysis />,
    },
    {
        path: '/trade-simulation',
        name: 'Trade simulation',
        element: <TradeSimulation />,
    },
    {
        path: '/trade-archiving',
        name: 'Trade Archiving',
        element: <TradeArchiving />,
    },
    {
        path: '/trade-confirmation',
        name: 'Trade Confirmation',
        element: <TradeConfirmation />,
    },
    {
        path: '/trade-cleaning',
        name: 'Trade Cleaning',
        element: <TradeCleaning />,
    },
    {
        path: '/settlement',
        name: 'Settlement',
        element: <Settlement />,
    },
    {
        path: '/trade-enrichment',
        name: 'Trade Enrichment',
        element: <TradeEnrichment />,
    },
    {
        path: '/compliance-reporting',
        name: 'Compliance Reporting',
        element: <ComplainceReporting />,
    },
    {
        path: '/regulatory-reporting',
        name: 'Regulatory Reporting',
        element: <RegulatoryReporting />,
    },
    {
        path: '/customer-support',
        name: 'Customer Support',
        element: <CustomerSupport />,
    },
];
