const endPoints = {
    LOGIN: '/user/login/',
    FORGOT_PASSWORD: '/user/forgot-password',
    PROFILE: '/user/profile/',
    GET_OTP: '/user/send-otp/',
    SIGNUP: '/user/signup/',
    LOGIN_TWO_AUTH: '/user/two-factor-verify/',
    TWO_FACTOR_AUTH: '/user/two-factor-auth/',
    ONBOARDING_CLIENT: '/alpaca/create-alpaca-user/',
};
export default endPoints;
