import React from 'react';
import { CustomInput } from '../../shared/Inputs/CustomInput';
import { CustomDropdown } from '../../shared/Inputs/CustomDropdown';
import formValidation from '../../utils/validations';
import { taxIdTypeOptions } from '../../utils/dropdownConstants';
import PrimaryButton from '../../shared/Button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveStep, updateFormData } from '../../store1/actions/onBoardingAction';
import { showFormErrorsRedux } from '../../utils/commonFunctions';

const FirstStep = () => {
    const dispatch = useDispatch();
    const { data, activeStep } = useSelector((state) => state.onBoardForm);

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        dispatch(updateFormData({ [name]: value, formErrors }));
    };

    const handleNext = () => {
        let ignore = ['countryOfBirth', 'countryOfCitizenship', 'countryOfTaxResidence', 'fundingSource', 'taxId'];
        if (showFormErrorsRedux(data, dispatch, ignore)) {
            dispatch(setActiveStep(activeStep + 1));
        }
    };

    console.log(data, 'data>>');

    return (
        <div className="grid col-12 px-2 onboarding-form">
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="phoneNumber"
                data={data}
                onChange={handleChange}
            />
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="streetAddress"
                data={data}
                onChange={handleChange}
            />
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="city"
                data={data}
                onChange={handleChange}
            />
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="postalCode"
                data={data}
                onChange={handleChange}
            />
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="state"
                data={data}
                onChange={handleChange}
                col={4}
            />
            <CustomDropdown
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="taxIdType"
                data={data}
                onChange={handleChange}
                dropitems={taxIdTypeOptions}
            />
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="givenName"
                data={data}
                onChange={handleChange}
            />
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="familyName"
                data={data}
                onChange={handleChange}
            />
            <CustomInput
                extraClassName="text-start"
                inputClass="border-dark text-black-alpha-90"
                name="dateOfBirth"
                data={data}
                onChange={handleChange}
                type="date"
            />
            <div className="form-group clearfix text-left w-full text-center mx-2">
                <PrimaryButton className="text-white" onClick={handleNext}>
                    Next
                </PrimaryButton>
            </div>
        </div>
    );
};

export default FirstStep;
