import ReactApexChart from "react-apexcharts";
import React from 'react';

class TradeClearingHeatmap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: props.data,
            options: props.options,
        };
    }

    render() {
        return (
            <div>
                <div id="chart">
                    <ReactApexChart options={this.state.options} series={this.state.series} type="heatmap" height={450} />
                </div>
                <div id="html-dist"></div>
            </div>
        );
    }
}

export default TradeClearingHeatmap;
