import React from 'react';
import { Button, Card, Col, Table } from 'react-bootstrap';

const ComplainceReporting = () => {

    const tradeHistoryData = [
        {
            id: 1,
            stock: "IXIC",
            status: "Matched",
        },
        {
            id: 1,
            stock: "IXIC",
            status: "Discrepencies",
        },
        {
            id: 1,
            stock: "IXIC",
            status: "Matched",
        },
        {
            id: 1,
            stock: "IXIC",
            status: "Discrepencies",
        },
        {
            id: 1,
            stock: "IXIC",
            status: "Matched",
        },
        {
            id: 1,
            stock: "IXIC",
            status: "Discrepencies",
        },
    ];

    return (
        <>
            <Col className='d-flex justify-content-end' xl={12} lg={12}>
                <Button className="me-2 mb-4" variant="success">
                    Generate Reports
                </Button>
            </Col>

            <Col xl={12} lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Matching Engine</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Stocks</strong>
                                    </th>
                                    <th>
                                        <strong>Compliance Status</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {tradeHistoryData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.stock}</td>
                                        <td >{item.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default ComplainceReporting;
