import { types } from '../types/types';

const initialState = {
    activeStep: 0,
    data: {
        phoneNumber: '',
        streetAddress: '',
        city: '',
        postalCode: '',
        state: '',
        taxIdType: '',
        givenName: '',
        familyName: '',
        dateOfBirth: '',

        countryOfTaxResidence: '',
        fundingSource: '',
        taxId: '',
        countryOfBirth: '',
        countryOfCitizenship: '',
        isControlPerson: false,
        isAffiliatedExchangeOrFinra: false,
        isPoliticallyExposed: false,
        immediateFamilyExposed: false,

        agreements: [
            { agreement: '', signedAt: null, ipAddress: '' },
            { agreement: '', signedAt: null, ipAddress: '' },
            { agreement: '', signedAt: null, ipAddress: '' },
        ],
    },
};

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ACTIVE_STEP:
            return { ...state, activeStep: action.payload };
        case types.UPDATE_FORM_DATA:
            console.log(action.payload);
            return {
                ...state,
                data: { ...state.data, ...action.payload },
                formErrors: { ...state.formErrors, ...action.payload.formErrors },
            };
        case types.UPDATE_AGREEMENT_FIELD:
            const { index, name, value, formErrors, fieldName } = action.payload;
            console.log(index, name, value, formErrors, fieldName);

            // Clone the existing field array
            const updatedFieldArray = [...state.data[fieldName]];

            // Update the specific field at the given index
            updatedFieldArray[index] = {
                ...updatedFieldArray[index],
                [name]: value,
                formErrors: {
                    ...updatedFieldArray[index].formErrors,
                    ...formErrors,
                },
            };

            return {
                ...state,
                data: {
                    ...state.data,
                    [fieldName]: updatedFieldArray,
                },
            };

        // case types.UPDATE_AGREEMENT_FIELD:
        //     const { index, name, value } = action.payload;
        //     const updatedAgreements = [...state.data.agreements];
        //     updatedAgreements[index] = { ...updatedAgreements[index], [name]: value };
        //     console.log(action.payload, 'updatedAgreements');
        //     return { ...state, data: { ...state.data, agreements: updatedAgreements } };
        case types.RESET_FORM:
            return initialState;

        default:
            return state;
    }
};

export default formReducer;
