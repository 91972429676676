import React from 'react';
import { Card, Col, Table } from 'react-bootstrap';

const TradeEnrichment = () => {
    const data = [
        {
            id: 1,
            tradeID: '#84668340',
            tradeDate: '09-03-2024',
            counterparty: 'Counterparty A',
            tradeValue: '0.7155',
        },
        {
            id: 1,
            tradeID: '#84668340',
            tradeDate: '09-03-2024',
            counterparty: 'Counterparty A',
            tradeValue: '0.7155',
        },
        {
            id: 1,
            tradeID: '#84668340',
            tradeDate: '09-03-2024',
            counterparty: 'Counterparty C',
            tradeValue: '0.7155',
        },
        {
            id: 1,
            tradeID: '#84668340',
            tradeDate: '09-03-2024',
            counterparty: 'Counterparty B',
            tradeValue: 'Matched',
        },
        {
            id: 1,
            tradeID: '#84668340',
            tradeDate: '09-03-2024',
            counterparty: 'Counterparty D',
            tradeValue: '0.7155',
        },
        {
            id: 1,
            tradeID: '#84668340',
            tradeDate: 'Discrepencies',
            counterparty: 'Counterparty A',
            tradeValue: '0.7155',
        },
    ];

    return (
        <>
            <Col xl={12} lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Enrichment with Reference Data</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Trade ID</strong>
                                    </th>
                                    <th>
                                        <strong>Trade Date</strong>
                                    </th>
                                    <th>
                                        <strong>Counterparty</strong>
                                    </th>
                                    <th>
                                        <strong>Trade Value</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.tradeID}</td>
                                        <td>{item.tradeDate}</td>
                                        <td>{item.counterparty}</td>
                                        <td>{item.tradeValue}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default TradeEnrichment;
