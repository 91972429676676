import React from 'react';
import ReactApexChart from 'react-apexcharts';

class TradeClearingBarChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: props.series,
            options: props.options,
        };
    }

    render() {
        return (
            <div id="chart" className="line-chart-style bar-chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={300} />
            </div>
        );
    }
}

export default TradeClearingBarChart;
