import React from 'react';
import { CustomCard } from '../shared/Cards/CustomCards';
import { Col, Row, Table } from 'react-bootstrap';
import CandlestickChart from '../jsx/components/Dashboard/CandlestickChart/CandlestickChart';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import { Link } from 'react-router-dom';

const RunTesting = () => {
    const tradeHistoryData = [
        {
            id: 1,
            symbol: 'AAPL',
            last: '1.1825',
            chg: '-0.0005',
        },
        {
            id: 2,
            symbol: 'AXP',
            last: '1.1825',
            chg: '-0.0005',
        },
        {
            id: 3,
            symbol: 'BA',
            last: '1.1825',
            chg: '-0.0005',
        },
    ];
    const ApexLine1 = loadable(() => pMinDelay(import('./../jsx/components/Dashboard/LineChart/Line1'), 1000));
    const ApexLine2 = loadable(() => pMinDelay(import('./../jsx/components/Dashboard/LineChart/Line2'), 1000));
    return (
        <Row>
            <Col md={12}>
                <CustomCard title="Expand Indicator List">
                    <CandlestickChart />
                </CustomCard>
            </Col>
            <Col md={12}>
                <CustomCard title="Strategy Tester">
                    <ApexLine2 />
                </CustomCard>
            </Col>
            <Col md={12}>
                <CustomCard title="Performance Chart">
                    <ApexLine1 />
                </CustomCard>
            </Col>
            <Col md={6}>
                <CustomCard title="Currencies">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Symbol</strong>
                                </th>
                                <th>
                                    <strong>Last</strong>
                                </th>
                                <th>
                                    <strong>Chg</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tradeHistoryData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.symbol}</td>
                                    <td className="">{item.last}</td>
                                    <td className="text-danger">{item.chg}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CustomCard>
            </Col>
            <Col md={6} className="widget-timeline dz-scroll style-1">
                <CustomCard title="Alerts">
                    <ul className="timeline ">
                        <li>
                            <div className="timeline-badge primary" />
                            <Link className="timeline-panel c-pointer text-muted" to="#">
                                <span>10 minutes ago</span>
                                <h6 className="mb-0">
                                    {' '}
                                    Youtube, a video-sharing website, goes live{' '}
                                    <strong className="text-primary">$500</strong>.
                                </h6>
                            </Link>
                        </li>
                        <li>
                            <div className="timeline-badge info"></div>
                            <Link className="timeline-panel c-pointer text-muted" to="#">
                                <span>20 minutes ago</span>
                                <h6 className="mb-0">
                                    New order placed <strong className="text-info">#XF-2356.</strong>
                                </h6>
                                <p className="mb-0"> Quisque a consequat ante Sit amet magna at volutapt...</p>
                            </Link>
                        </li>
                        <li>
                            <div className="timeline-badge danger"></div>
                            <Link className="timeline-panel c-pointer text-muted" to="#">
                                <span>30 minutes ago</span>
                                <h6 className="mb-0">
                                    john just buy your product <strong className="text-warning">Sell $250</strong>
                                </h6>
                            </Link>
                        </li>
                        <li>
                            <div className="timeline-badge success"></div>
                            <Link className="timeline-panel c-pointer text-muted" to="#">
                                <span>15 minutes ago</span>
                                <h6 className="mb-0">StumbleUpon is acquired by eBay. </h6>
                            </Link>
                        </li>
                        <li>
                            <div className="timeline-badge warning"></div>
                            <Link className="timeline-panel c-pointer text-muted" to="#">
                                <span>20 minutes ago</span>
                                <h6 className="mb-0">Mashable, a news website and blog, goes live.</h6>
                            </Link>
                        </li>
                    </ul>
                </CustomCard>
            </Col>
        </Row>
    );
};

export default RunTesting;
