import api from '../services/api';
import EndPoints from '../services/endPoints';
import { entries, notEqual, values } from './javascript';
import formValidation from './validations';
import moment from 'moment';
import constants from '../constants';
import { updateFormData } from '../store1/actions/onBoardingAction';

const showFormErrors = (data, setData, ignore) => {
    let formErrors = {};
    entries(data).forEach(([key, value]) => {
        formErrors = {
            ...formErrors,
            ...formValidation(key, value, data, ignore),
        };
    });
    ignore?.forEach((name) => {
        if (formErrors[name]) {
            formErrors[name] = '';
        }
    });
    setData({ ...data, formErrors });
    return !values(formErrors).some((v) => notEqual(v, ''));
};
const showFormErrorsRedux = (data, dispatch, ignore) => {
    let formErrors = {};

    Object.entries(data).forEach(([key, value]) => {
        formErrors = {
            ...formErrors,
            ...formValidation(key, value, data, ignore),
        };
    });

    ignore?.forEach((name) => {
        if (formErrors[name]) {
            formErrors[name] = '';
        }
    });

    console.log(formErrors, 'formErrors');
    dispatch(updateFormData({ ...data, formErrors }));
    return !Object.values(formErrors).some((v) => v !== '');
};
const showArrayFormErrors = (array, ignore) => {
    let isValid = true;
    let res = array.map((data) => {
        let formErrors = {};
        entries(data).forEach(([key, value]) => {
            formErrors = {
                ...formErrors,
                ...formValidation(key, value, data, ignore),
            };
        });
        ignore?.forEach((name) => {
            if (formErrors[name]) {
                formErrors[name] = '';
            }
        });
        if (values(formErrors).some((v) => notEqual(v, ''))) {
            isValid = false;
        }
        return { ...data, formErrors };
    });

    return { isValid, data: res };
};

const capitalizeCamelCase = (str) => {
    let words = str.split(/(?=[A-Z])/);
    let capitalizedWords = words.map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    let capitalizedString = capitalizedWords.join(' ');
    return capitalizedString;
};

// const confirmDelete = (
//     onDeleteAction,
//     confirmationMessage,
//     position = 'center',
//     confirmationHeader = 'Delete Confirmation',
// ) => {
//     confirmDialog({
//         message: confirmationMessage,
//         icon: 'pi pi-info-circle',
//         header: confirmationHeader,
//         acceptClassName: 'bg-main',
//         position,
//         accept: () => {
//             onDeleteAction();
//         },
//         reject: () => {},
//     });
// };
const uploadFile = async (file) => {
    if (typeof file === 'string') {
        return file;
    } else {
        const formData = new FormData();
        formData.append('file', file);
        const res = await api('post', EndPoints.UPLOAD_FILES, formData, {}, 'multipart/form-data');

        if (res.success && res.data) {
            return res.data.path;
        }
    }
};
const uploadSignImage = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const res = await api('post', EndPoints.UPLOAD_FILES, formData, {}, 'multipart/form-data');

    if (res.success && res.data) {
        return res.data.path;
    }
};

const uploadImages = async (images) => {
    const promises = images?.map(async (item) => {
        if (typeof item === 'string') {
            return item;
        } else {
            const formData = new FormData();
            formData.append('file', item);
            const res = await api('post', EndPoints.UPLOAD_FILES, formData, {}, 'multipart/form-data');
            if (res.success && res.data) {
                return res.data.path;
            }
        }
    });

    const urls = await Promise.all(promises);
    return urls;
};

const uploadFiles = async (files) => {
    const promises = files.map(async (item) => {
        if (typeof item === 'string') {
            return item;
        } else {
            const formData = new FormData();
            formData.append('file', item);
            const res = await api('post', EndPoints.UPLOAD_FILES, formData, {}, 'multipart/form-data');
            if (res.success && res.data) {
                return {
                    path: res.data.path,
                    originalname: res.data.originalname,
                    size: res.data.size,
                };
            }
        }
    });

    const filesArray = await Promise.all(promises);
    return filesArray;
};

const mobileFormatted = (phoneNumber) => {
    if (phoneNumber) {
        const cleaned = phoneNumber?.toString().replace(/\D/g, '');
        const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
    }
    return phoneNumber;
};

const spaceToDash = (inputString) => {
    return inputString.replace(/ /g, '-').toLowerCase();
};

const dashToSpace = (inputString) => {
    return inputString
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const removeUnusedKeys = (payload) => {
    return Object.fromEntries(
        Object.entries(payload).filter(([key, value]) => {
            return value !== undefined && value !== null && value !== '' && value !== 0; // Check for truthy values, including 0
        }),
    );
};

const getIds = (item) => {
    return item?.map((list) => list._id);
};
const getTime = (date) => {
    return moment(new Date(date)).format('H:mm');
};

const convertToDateTime = (timeString) => {
    var parts = timeString.split(':');
    var hours = parseInt(parts[0], 10);
    var minutes = parseInt(parts[1], 10);
    var newDate = new Date();
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    return newDate;
};

const getImageUrl = (image) => {
    if (image.includes('http')) {
        return image;
    } else {
        return constants.baseUrl + image;
    }
};

function isFileObject(obj) {
    return obj instanceof File;
}

const dateConversions = (date) => {
    const formattedDate = moment(date).format('DD-MM-YYYY');
    const formattedTime = moment(date).format('hh:mm');
    return { formattedDate, formattedTime };
};

function adjustTime(e) {
    let currentMin = e.value.getMinutes();
    let startTime = e.value;
    if (currentMin % 5 !== 0) {
        startTime = new Date(e.value.getTime() + (5 - (currentMin % 5)) * 60000);
    }
    return startTime;
}

const timeString = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const time = `${hours}:${minutes}`;
    return time;
};
const timeConvertToDate = (time) => {
    const [hours, minutes] = time.split(':');
    const currentDate = new Date();
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    return currentDate;
};

export {
    capitalizeCamelCase,
    showFormErrors,
    mobileFormatted,
    spaceToDash,
    dashToSpace,
    uploadFile,
    uploadFiles,
    uploadImages,
    removeUnusedKeys,
    getIds,
    getTime,
    convertToDateTime,
    getImageUrl,
    isFileObject,
    uploadSignImage,
    dateConversions,
    adjustTime,
    timeString,
    timeConvertToDate,
    showFormErrorsRedux,
    showArrayFormErrors,
};
