import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { CustomCard } from '../shared/Cards/CustomCards';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    const tradeHistoryData = [
        {
            id: 1,
            instrument: 'ALPHABET INC-CL C',
            companyname:"ALPHABET INC-CL C",
            ordernumber: "208813720",
            quantity: "9698.37",
            averagecost: "200 DMA BreakDown 1D",
            currentprice: "45.63",
            currentvalue: "3:06:45 PM",
            status: "+0.6"
        },
        {
            id: 2,
            instrument: 'ALPHABET INC-CL C',
            companyname:"ALPHABET INC-CL C",
            ordernumber: "208813720",
            quantity: "9698.37",
            averagecost: "200 DMA BreakDown 1D",
            currentprice: "45.63",
            currentvalue: "3:06:45 PM",
            status: "+0.6"
        },
        {
            id: 3,
            instrument: 'ALPHABET INC-CL C',
            companyname:"ALPHABET INC-CL C",
            ordernumber: "208813720",
            quantity: "9698.37",
            averagecost: "200 DMA BreakDown 1D",
            currentprice: "45.63",
            currentvalue: "3:06:45 PM",
            status: "+0.6"
        },
        {
            id: 4,
            instrument: 'ALPHABET INC-CL C',
            companyname:"ALPHABET INC-CL C",
            ordernumber: "208813720",
            quantity: "9698.37",
            averagecost: "200 DMA BreakDown 1D",
            currentprice: "45.63",
            currentvalue: "3:06:45 PM",
            status: "+0.6"
        },
        {
            id: 5,
            instrument: 'ALPHABET INC-CL C',
            companyname:"ALPHABET INC-CL C",
            ordernumber: "208813720",
            quantity: "9698.37",
            averagecost: "200 DMA BreakDown 1D",
            currentprice: "45.63",
            currentvalue: "3:06:45 PM",
            status: "-0.6"
        },
        {
            id: 6,
            instrument: 'ALPHABET INC-CL C',
            companyname:"ALPHABET INC-CL C",
            ordernumber: "208813720",
            quantity: "9698.37",
            averagecost: "200 DMA BreakDown 1D",
            currentprice: "45.63",
            currentvalue: "3:06:45 PM",
            status: "+0.6"
        },
    ];
    return <Row>
        <Col md={12}>
            <CustomCard title="Positions">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>
                                <strong>Instrument</strong>
                            </th>
                            <th>
                                <strong>Quantity</strong>
                            </th>
                            <th>
                                <strong>Average Cost</strong>
                            </th>
                            <th>
                                <strong>Current quantity</strong>
                            </th>
                            <th>
                                <strong>Current Value</strong>
                            </th>
                            <th>
                                <strong>Profit/ Loss</strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {tradeHistoryData.map((item, index) => (
                            <tr key={index}>
                                <td><Link to="" className='text-primary text-decoration-underline m-0'>{item.instrument}</Link>
                                    <p className='m-0'>{item.companyname}</p>
                                    <p className='m-0'>{item.ordernumber}</p>
                                </td>
                                <td className="">{item.quantity}</td>
                                <td className="">{item.averagecost}</td>
                                <td className="">{item.currentprice}</td>
                                <td className="">{item.currentvalue}</td>
                                <td className=""><div className={`p-2 px-3 rounded-pill ${item.status.startsWith('-') ? 'negative-indicator' : 'positive-indicator'}`}>{item.status}</div></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CustomCard>
        </Col>
    </Row>;
};

export default Portfolio;
