import React from 'react';
import Calendar from '../jsx/components/AppsMenu/Calendar/Calendar';

const RegulatoryReporting = () => {
    return <div>
        <Calendar />
    </div>;
};

export default RegulatoryReporting;
