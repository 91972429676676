import React from 'react';

const CustomInputSwitch = ({ className, value, onChange, name, data, label, id = 'flexSwitchCheckDefault' }) => {
    return (
        <div className="form-check form-switch  my-2">
            <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id={id}
                name={name}
                checked={value || data?.[name]}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.checked })}
            />
            <label className="form-check-label" for={id}>
                {label}
            </label>
        </div>
    );
};

export default CustomInputSwitch;
