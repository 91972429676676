import { Card } from 'react-bootstrap';
import bg6 from '../../images/background/bg6.jpg';

export function CustomGridLayout({ children, extraClass }) {
    return <div className={`grid ${extraClass}`}>{children}</div>;
}
export function CustomBackgroundLayout({ children, extraClass }) {
    return (
        <div className="bg-img-fix overflow-hidden" style={{ background: '#fff url(' + bg6 + ')', height: '100vh' }}>
            {children}
        </div>
    );
}

export function CustomCard({ children, title, extraClass }) {
    return (
        <Card>
            {title ?
                <Card.Header>
                    <Card.Title>{title}</Card.Title>
                </Card.Header>
                : null}
            <Card.Body>
                {children}
            </Card.Body>
        </Card>

    );
}

