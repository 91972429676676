import React from 'react';

const CustomCheckbox = ({ className, value, onChange, name, data, label,id = 'check1' }) => {
    return (
        <div className="form-check mt-2  col-6">
            <input
                type="checkbox"
                className="form-check-input"
                id={id}
                name={name}
                checked={value || data?.[name]}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.checked })}
            />
            <label className={`form-check-label ${className}`} htmlFor={id}>
                {label}
            </label>
        </div>
    );
};

export default CustomCheckbox;
